import React, { Component } from "react";
import styled from "styled-components";
import { Row } from "react-grid-system";
import ReloadIcon from "../assets/icons/reload.svg";

class CustomNavbar extends Component {
  render() {
    return (
      <>
        <NavbarWrapper>
          <Row align="center" justify="between">
            <h1>{this.props.title}</h1>
            <div onClick={this.props.onClick}>{this.props.icon}</div>
            <img
              src={ReloadIcon}
              alt=""
              height="20px"
              onClick={() => window.location.reload()}
            />
          </Row>
        </NavbarWrapper>
      </>
    );
  }
}

export default CustomNavbar;

const NavbarWrapper = styled.div`
  padding: 15px 40px 0px 40px;
  color: white;

  h1 {
    text-transform: capitalize;
  }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import { MobileContainer } from "../../components/global";
import RyvoLogo from "../../assets/images/logo.png";
import disableScroll from "disable-scroll";
import { useNavigate } from "react-router-dom";

export default function SplashPage() {
  const navigate = useNavigate();
  useEffect(() => {
    disableScroll.on();
    setTimeout(() => {
      navigate("/auth");
    }, 2000);
  }, []);

  return (
    <>
      <MobileContainer style={{ height: "100vh" }}>
        <center>
          <SplashLogo src={RyvoLogo} alt="" />
        </center>
      </MobileContainer>
    </>
  );

}


const SplashLogo = styled.img`
  margin: 35vh auto;
  height: 100px;
`;

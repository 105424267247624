import React, { useState, useEffect } from "react";
import {
  CustomInput,
  MobileContainer,
  CustomButton,
} from "../../components/global";
import { Row, Col } from "react-grid-system";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import disableScroll from "disable-scroll";
import CustomUser from "../../components/CustomUser";
import CustomPagination from "../../components/CustomPagination";
import { Toast } from "antd-mobile";
import {
  getUsers,
  activeUser,
  verifyUser,
  verifyUsers,
} from "../../services/users.service";

export default function UsersPage(props) {
  const [index, setIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [chunks, setChunks] = useState([]);
  const [currChunk, setCurrChunk] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [chunkSize, setChunkSize] = useState(100);

  useEffect(() => {
    window.scroll(0, 0);
    disableScroll.off();
    afterPageClicked(1);
  }, []);

  function onDelete(index) {
    // let tempItems = this.state.users;
    // tempItems.splice(index, 1);
    // this.setState({ users: tempItems });
    // Toast.fail("Deleted successfuly", 1);
  }

  function onActive(index) {
    activeUser(usersData[index]._id)
      .then(() => {
        setUsersData(users => {
          users[index].isActive = true;
          return [...users];
        });
        Toast.success("User activated", 1);
      })
      .catch(() => {
        Toast.success("User activation fail", 1);
      });
  }

  function onVerify(index) {
    if (window.confirm("Are you sure you want to verify this user?")) {
      verifyUser(usersData[index]._id)
        .then(() => {
          setUsersData(users => {
            users[index].isVerified = true;
            return [...users];
          });
          Toast.success("User verified", 1);
        })
        .catch(() => {
          Toast.success("User verification fail", 1);
        });
    }
  }

  const onSelectAll = checked => {
    console.log(checked);
    if (checked) {
      usersData.forEach(u => {
        if (!selectedUsers.includes(u._id)) {
          selectedUsers.push(u._id);
          setSelectedUsers(selectedUsers);
        }
      });
    } else if (!checked) {
      usersData.forEach(u => {
        if (selectedUsers.includes(u._id)) {
          const index = selectedUsers.indexOf(u._id);
          if (index !== -1) {
            selectedUsers.splice(index, 1);
          }
          setSelectedUsers(selectedUsers);
        }
      });
    }
    console.log(JSON.stringify({ selectedUsers }, null, 3));
  };

  const onSelect = (checked, user) => {
    // console.log("onSelect");
    // console.log(checked);
    // console.log(user);
    if (checked && !selectedUsers.includes(user._id)) {
      selectedUsers.push(user._id);
      setSelectedUsers(selectedUsers);
    } else if (!checked && selectedUsers.includes(user._id)) {
      const index = selectedUsers.indexOf(user._id);
      if (index !== -1) {
        selectedUsers.splice(index, 1);
      }
      setSelectedUsers(selectedUsers);
    }
    // console.log(JSON.stringify({ selectedUsers }, null, 3));
  };

  // const onVerify = async (user, username, isVerified) => {
  //   //console.log(user, isVerified);
  //   Toast.success("Verifying...");
  //   usersData.filter(u => u.username === username)[0].isVerified = !isVerified;
  //   const response = await verifyUsers({
  //     id: user._id,
  //     username: username,
  //     isVerified: !isVerified,
  //   });
  //   setUsersData(usersData);
  //   Toast.success("User verified");
  //   console.log(
  //     usersData.map(u => ({
  //       username: u.username,
  //       isVerified: u.isVerified,
  //     }))
  //   );
  //   console.log(response.data);
  // };

  const chunk = (a, n) =>
    [...Array(Math.ceil(a.length / n))].map((_, i) =>
      a.slice(n * i, n + n * i)
    );

  const afterPageClicked = page_number => {
    setCurrPage(page_number);
    // console.log(page_number - 1);
    getUsers(page_number - 1).then(res => {
      // console.log(res);
      setUsersData(res.users);
      setUsersCount(res.count);
      Toast.success(`Page ${page_number} fetched`, 2);
    });
  };

  return (
    <>
      <CustomNavbar title="users" />
      <MobileContainer style={{ padding: "20px" }}>
        <Row align="center" justify="start">
          <Col xs={1}>
            <input
              type="checkbox"
              defaultChecked={false}
              onChange={e => onSelectAll(e.target.checked)}
            />
          </Col>
          <Col xs={8}>
            <CustomInput
              placeholder="Search users"
              onInput={e => setSearchValue(e.target.value)}
            />
          </Col>
          {/* <Col xs={3}>
            <CustomButton type="button" onClick={() => onVerify(usersData)}>Verify</CustomButton>
          </Col> */}
        </Row>
        <br />
        {usersData && usersData.length > 0 ? (
          usersData
            .filter(
              u =>
                u.username?.includes(searchValue) ||
                u.name?.includes(searchValue) ||
                u.email?.includes(searchValue) ||
                u.phone?.includes(searchValue)
            )
            .map((u, index) => {
              return (
                <CustomUser
                  key={`${u._id}`}
                  user={u}
                  defaultChecked={selectedUsers.includes(u._id)}
                  onAccept={() => onActive(index)}
                  onVerify={() => onVerify(index)}
                  onDelete={() => onDelete(index)}
                  onSelect={e => onSelect(e.target.checked, u)}
                />
              );
            })
        ) : (
          <center>
            <p style={{ margin: "30px" }}>No Users</p>
          </center>
        )}
      </MobileContainer>
      <MobileContainer>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <p>{ }</p>
            {usersCount > 0 && (
              <CustomPagination
                totPages={Math.ceil(usersCount / chunkSize)}
                currentPage={currPage}
                pageClicked={ele => {
                  afterPageClicked(ele);
                }}
              />
            )}
          </Col>
        </Row>
      </MobileContainer>
      <CustomTabBar />
    </>
  );
}

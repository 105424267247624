import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import avatar from "../assets/images/post-wp.jpg";

export default function CustomUser({
  user,
  defaultChecked,
  onAccept,
  onVerify,
  onDelete,
  onSelect,
}) {
  return (
    <Swipeout
      style={{ margin: "10px 0" }}
      left={[
        {
          text: "Accept",
          onPress: onAccept,
          style: {
            backgroundColor: "#49C628",
            color: "white",
            borderRadius: "10px 0 0 10px",
          },
          className: "custom-class-1",
        },
      ]}
      right={[
        {
          text: "Delete",
          onPress: onDelete,
          style: {
            backgroundColor: "#F82A19",
            color: "white",
            borderRadius: "0 10px 10px 0",
          },
          className: "custom-class-2",
        },
      ]}
    >
      <UsersWrapper>
        <Row align="center" justify="start">
          <Col xs={1}>
            <input
              type="checkbox"
              defaultChecked={defaultChecked}
              onChange={onSelect}
            />
          </Col>
          <Col xs={1}>{user.isVerified && <>✅</>}</Col>
          <Col xs={2}>
            <UserAvatar src={avatar} />
          </Col>
          <Col xs={2}>
            <UsersInfos>
              <Row align="center" gutterWidth={0}>
                <h5>{user.username}</h5>
                <small>{user.createdAt}</small>
              </Row>
            </UsersInfos>
          </Col>
          <Col xs={4}>
            <UsersInfos><p>{user.email}</p></UsersInfos>
          </Col >
          {!user.isActive && <Col xs={2}>
            <ActiveButton type="button" onClick={onAccept}>Activate</ActiveButton>
          </Col>}
          {user.isActive && !user.isVerified && <Col xs={2}>
            <VerfiyButton type="button" onClick={onVerify}>Verify</VerfiyButton>
          </Col>}
        </Row>
      </UsersWrapper>
    </Swipeout>
  );
}


const UserAvatar = styled.img`
  height: 25px;
  width: 25px;
  border-radius: 60px;
  object-fit: cover;
`;

const UsersInfos = styled.div`
  color: white;

  h5 {
    margin: 0;
    font-size: 13px;
    margin-right: 10px;
  }
  small {
    margin: 0;
    font-size: 10px;
  }
  p {
    margin: 0;
  }
`;

const UsersWrapper = styled.div`
  background: #212121;
  padding: 15px 20px;
  border-radius: 5px;
`;

const ActiveButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 0.25rem 0.75rem;
`;

const VerfiyButton = styled.button`
  background-color: #0982ff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 0.25rem 0.75rem;
`;

import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import "../styles/customPost.css";
import { useNavigate } from "react-router-dom";

export default function CustomPost(props) {
  const navigate = useNavigate();

  function convertTags(tag) {
    switch (tag) {
      case "SEXUAL CONTENT":
        return <span>🍆</span>;

      case "VIOLENCE":
        return <span>🥊</span>;

      case "SPAM":
        return <span>🤖</span>;

      case "FAKE NEWS":
        return <span>📰</span>;

      case "OFFENSIVE":
        return <span>🤬</span>;

      default:
        return <span>No tags</span>;
    }
  }

  const { subtype, tags, message, image, id, reportId, onDelete, onArchive } = props;

  return (
    <>
      <Swipeout
        style={{
          backgroundColor: "#212121 !important",
          margin: "15px 0px",
        }}
        right={[
          {
            text: "Delete",
            onPress: onDelete,
            style: {
              backgroundColor: "#F82A19",
              color: "white",
              borderRadius: "0 15px 15px 0",
            },
          },
        ]}
        left={[
          {
            text: "Archive",
            onPress: onArchive,
            style: {
              backgroundColor: "#4CD964",
              color: "white",
              borderRadius: "15px 0 0 15px",
            },
          },
        ]}
      // onOpen={() => console.log("deleted")}
      >
        <PostCard onClick={() => navigate(`/post/${id}`, { state: { reportId: reportId } })}>
          <Row gutterWidth={20}>
            <Col xs={4}>
              <AvatarImg src={image} alt="" />
            </Col>
            <Col xs={8}>
              <CardContent>
                <h4>{subtype}</h4>
                <h5>
                  {tags.map((tag) => {
                    return <span>{convertTags(tag)}</span>;
                  })}
                </h5>
                <p>{message.length > 0 ? message : "No message"}</p>
              </CardContent>
            </Col>
          </Row>
        </PostCard>
      </Swipeout>
    </>
  );
}

const AvatarImg = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100px;
  object-fit: cover;
`;

const PostCard = styled.div`
  height: 100%;
  background: #212121 !important;
  padding: 10px;
  border-radius: 15px;
  background-size: cover;
  margin: 0;
`;

const CardContent = styled.div`
  color: white;
  padding-right: 10px;
  background: #212121;
  height: 100%;

  h4 {
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    font-size: 13px;
    text-transform: capitalize;
  }

  h5 {
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
    text-transform: capitalize;
  }

  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }
`;

import React, { Component } from "react";
import { CustomInput, MobileContainer } from "../../components/global";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import disableScroll from "disable-scroll";
import CustomReport from "../../components/CustomReport";
import { Toast } from "antd-mobile";
import { getUsers } from "../../services/usersReports.service";

class UsersReportsPage extends Component {
  state = {
    searchValue: "",
    index: 0,
  };

  componentDidMount() {
    window.scroll(0, 0);
    disableScroll.off();

    const { index } = this.state;

    getUsers(index).then((res) => {
      console.log(res);
      this.setState({
        usersObj: res,
      });
    });
  }

  onDelete(index) {
    let tempItems = this.state.users;
    tempItems.splice(index, 1);
    this.setState({ users: tempItems });
    Toast.fail("Deleted successfuly", 1);
  }

  onAccept(index) {
    let tempItems = this.state.users;
    tempItems.splice(index, 1);
    this.setState({ users: tempItems });
    Toast.success("Accepted successfuly", 1);
  }

  render() {
    const { usersObj } = this.state;
    return (
      <>
        <CustomNavbar title="users" />
        <MobileContainer style={{ padding: "20px" }}>
          <CustomInput
            placeholder="Search users"
            onInput={(e) => this.setState({ searchValue: e.target.value })}
          />
          <br />
          {usersObj && usersObj.users && usersObj.users.length > 0 ? (
            usersObj?.users.map((report, index) => {
              return (
                <CustomReport
                  key={`${report._id}`}
                  user={report.user}
                  onAccept={() => this.onAccept(index)}
                  onDelete={() => this.onDelete(index)}
                />
              );
            })
          ) : (
            <center>
              <p style={{ margin: "30px" }}>No reported Users</p>
            </center>
          )}
        </MobileContainer>
        <CustomTabBar />
      </>
    );
  }
}

export default UsersReportsPage;

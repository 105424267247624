import React, { Component } from "react";
import { Toast } from "antd-mobile";
import { MobileContainer } from "../../components/global";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import disableScroll from "disable-scroll";
import CustomComment from "../../components/CustomComment";
import { deleteComments, getComments } from "../../services/comments.service";
import { deleteReport } from "../../services/reports.service";

class CommentsPage extends Component {
  state = {
    index: 0,
  };
  componentDidMount() {
    disableScroll.off();
    const { index } = this.state;
    getComments(index).then((res) => {
      console.log(res);
      this.setState({ commentsObj: res });
    });
  }

  onDelete(id, index) {
    deleteComments(id).then((_) => {
      var tmpArray = [...this.state.commentsObj.comments];
      tmpArray.splice(index, 1);
      var tmpObj = this.state.commentsObj;
      tmpObj.comments = tmpArray;
      tmpObj.count--;
      this.setState({ commentsObj: tmpObj });
      Toast.success("Comment deleted", 2);
    });
  }

  onArchive(id, index) {
    deleteReport(id).then((res) => {
      var tmpArray = [...this.state.commentsObj.comments];
      tmpArray.splice(index, 1);
      var tmpObj = this.state.commentsObj;
      tmpObj.comments = tmpArray;
      tmpObj.count--;
      this.setState({ commentsObj: tmpObj });
      Toast.success("Report deleted", 2);
    });
  }

  render() {
    const { commentsObj } = this.state;
    return (
      <>
        <CustomNavbar title="comments" />
        <MobileContainer style={{ paddingTop: "5px" }}>
          {commentsObj?.comments.map((report, index) => {
            console.log(report.commentReported._id);
            return (
              <CustomComment
                key={report._id}
                comment={report.commentReported}
                date={report.date}
                reportMessage={report.message}
                onArchive={() => this.onArchive(report._id, index)}
                onDelete={() =>
                  this.onDelete(report.commentReported._id, index)
                }
              />
            );
          })}
        </MobileContainer>
        <CustomTabBar />
      </>
    );
  }
}

export default CommentsPage;

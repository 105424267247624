import React, { Component } from "react";
import { MobileContainer } from "../../components/global";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import disableScroll from "disable-scroll";
import {
  deleteContests,
  getNewContests,
  verifyContest,
} from "../../services/contests.service";
import { Toast } from "antd-mobile";
import CustomPending from "../../components/CustomPending";

class ContestsPending extends Component {
  state = {
    isFocused: false,
    index: 0,
  };

  componentDidMount() {
    disableScroll.off();
    const { index } = this.state;

    getNewContests(index).then((res) => {
      console.log(res);
      this.setState({ contestsObj: res });
    });
  }

  validateContest(id) {
    verifyContest(id).then((res) => {
      console.log("verify", res);
      Toast.success("Contest verified", 2, () => window.location.reload());
    });
  }

  deleteContest(id) {
    deleteContests(id).then((_) => {
      Toast.success("Post deleted", 2, () => window.location.reload());
    });
  }

  render() {
    const { contestsObj } = this.state;

    return (
      <>
        <CustomNavbar title="Pending" />
        <MobileContainer style={{ padding: "20px", paddingTop: "0px" }}>
          {contestsObj?.contests?.map((contest) => {
            return (
              <div key={contest._id}>
                <CustomPending
                  contest={contest}
                  onDelete={() => this.deleteContest(contest._id)}
                  onArchive={() => this.validateContest(contest._id)}
                />
              </div>
            );
          })}
        </MobileContainer>

        <CustomTabBar />
      </>
    );
  }
}

export default ContestsPending;

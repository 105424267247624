import React, { Component } from "react";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import { MobileContainer } from "../../components/global";
import MDEditor from "@uiw/react-md-editor";

class PoliciesPage extends Component {
  state = {
    value: "",
  };

  render() {
    const { value } = this.state;
    return (
      <div>
        <CustomNavbar title="policies" />
        <MobileContainer>
          <h3>Edit Policies</h3>
          <hr />
          <MDEditor
            value={value}
            onChange={(v) => this.setState({ value: v })}
          />
          <hr />
          {/* <MDEditor.Markdown source={value} /> */}
        </MobileContainer>
        <CustomTabBar />
      </div>
    );
  }
}

export default PoliciesPage;

import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import Moment from "react-moment";

class CustomComment extends Component {
  render() {
    const { comment, reportMessage, date } = this.props;

    return (
      <>
        <Swipeout
          style={{ margin: "10px 0" }}
          left={[
            {
              text: "Archive",
              onPress: this.props.onArchive.bind(this),
              style: {
                backgroundColor: "#49C628",
                color: "white",
                borderRadius: "10px 0 0 10px",
              },
              className: "custom-class-1",
            },
          ]}
          right={[
            {
              text: "Delete",
              onPress: this.props.onDelete.bind(this),
              style: {
                backgroundColor: "#F82A19",
                color: "white",
                borderRadius: "0 10px 10px 0",
              },
              className: "custom-class-2",
            },
          ]}
        >
          <CommentWrapper>
            <Row align="start">
              <Col xs={12}>
                <CommentInfos>
                  <Row align="center" gutterWidth={0}>
                    <h5>{comment?.user.username}</h5>
                    <span>
                      <Moment format="DD/MM/YYYY">{date}</Moment>
                    </span>
                  </Row>
                </CommentInfos>
              </Col>
              <br />
              <Col xs={12}>
                <CommentInfos>
                  <p>{comment?.comment}</p>
                  <span>
                    {reportMessage.length > 0 ? reportMessage : "Aucun message"}
                  </span>
                </CommentInfos>
              </Col>
            </Row>
          </CommentWrapper>
        </Swipeout>
      </>
    );
  }
}

export default CustomComment;

const CommentInfos = styled.div`
  color: white;

  h5 {
    margin: 0;
    font-size: 13px;
    margin-right: 10px;
  }

  span {
    font-size: 12px;
    color: lightgrey;
  }

  p {
    margin: 0;
    margin: 5px 0;
  }
`;

const CommentWrapper = styled.div`
  background: #212121;
  padding: 20px;
  border-radius: 5px;
`;

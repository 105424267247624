import styled from "styled-components";

export const MobileContainer = styled.div`
  min-height: 80vh;
  max-height: 100%;
  background: #000;
  padding: 30px;
  padding-bottom: 100px !important;
  color: #fff;
`;

export const LogoBrand = styled.img`
  height: 30px;
`;

export const CustomInput = styled.input`
  outline: none;
  box-shadow: none;
  border: none;
  font-size: 14px;
  font-weight: 400 !important;
  background: #fff;
  border-radius: 50px;
  padding: 10px 20px;
  width: 85%;
  margin-bottom: 15px;
`;

export const CustomButton = styled.button`
  box-shadow: none;
  outline: none;
  border: none;
  box-sizing: border-box;
  background: #f70731;
  border-radius: 3px;
  padding: 10px 40px;
  font-size: 15px;
  font-weight: 700;
  color: white;
  width: 100%;
  cursor: pointer;
`;

export const DisabledCustomButton = styled(CustomButton)`
  color: #81939c;
  background: #d2dce2;
  cursor: not-allowed;
`;

import React, { Component } from "react";
import { MobileContainer } from "../../components/global";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import disableScroll from "disable-scroll";
// import CustomFeedback from "../../components/CustomFeedback";
import { Toast } from "antd-mobile";

class FeedbackPage extends Component {
  state = {
    searchValue: "",
    feedback: [
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
      {
        username: "oussama",
        date: "12/07/2001",
        reports: "my super feedback!",
      },
    ],
  };

  componentDidMount() {
    window.scroll(0, 0);
    disableScroll.off();
  }

  onDelete(index) {
    let tempItems = this.state.feedback;
    tempItems.splice(index, 1);
    this.setState({ feedback: tempItems });
    Toast.fail("Deleted successfuly", 1);
  }

  onAccept(index) {
    let tempItems = this.state.feedback;
    tempItems.splice(index, 1);
    this.setState({ feedback: tempItems });
    Toast.success("Accepted successfuly", 1);
  }

  render() {
    // const { feedback } = this.state;
    return (
      <>
        <CustomNavbar title="Unvailable for Now" />
        <MobileContainer style={{ padding: "20px" }}>
          {/* <CustomInput
            placeholder="Search feedback"
            onInput={(e) => this.setState({ searchValue: e.target.value })}
          />
          <br />
          {feedback.map((user, index) => {
            return (
              <CustomFeedback
                key={`${user.username}`}
                user={user}
                onAccept={() => this.onAccept(index)}
                onDelete={() => this.onDelete(index)}
              />
            );
          })} */}
        </MobileContainer>
        <CustomTabBar />
      </>
    );
  }
}

export default FeedbackPage;

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";
import SplashPage from "./views/static/splashPage";
import ErrorPage from "./views/static/errorPage";
import PostsPage from "./views/posts/postsPage";
import CommentsPage from "./views/comments/commentsPage";
import ContestsPage from "./views/contests/contestsPage";
import FeedbackPage from "./views/feedback/feedbackPage";
import UsersPage from "./views/usersPage/usersPage";
import UsersReportsPage from "./views/usersReportsPage/usersReportsPage";
import PostDetail from "./views/posts/postDetail";
import ContestDetail from "./views/contests/contestDetail";
import ContestsPending from "./views/contests/contestPending";
import PoliciesPage from "./views/policies/policiesPage";
import AuthPage from "./views/static/authPage";

function App() {
  return (
    <LocaleProvider locale={enUS}>
      <Router>
        <Routes>
          <Route exact path="/" element={<SplashPage />} />

          <Route exact path="/auth" element={<AuthPage />} />
          <Route exact path="/posts" element={<PostsPage />} />
          <Route exact path="/contests/pending" element={<ContestsPending />} />
          <Route exact path="/contests" element={<ContestsPage />} />
          <Route exact path="/comments" element={<CommentsPage />} />
          <Route exact path="/users-reports" element={<UsersReportsPage />} />
          <Route exact path="/users" element={<UsersPage />} />

          <Route exact path="/post/:id" element={<PostDetail />} />
          <Route exact path="/contest/:id" element={<ContestDetail />} />
          <Route exact path="/contest/verify/:id" element={<ContestDetail />} />
          <Route exact path="/policies" element={<PoliciesPage />} />
          <Route exact path="/feedback" element={<FeedbackPage />} />

          <Route element={<ErrorPage />} />
        </Routes>
      </Router>
    </LocaleProvider>
  );
}

export default App;

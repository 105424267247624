import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MobileContainer } from "../../components/global";
import disableScroll from "disable-scroll";
import { Button } from "antd-mobile";
import { useNavigate } from "react-router-dom";

export default function AuthPage() {
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  useEffect(() => {
    // setToken(
    //   process.env.NODE_ENV === "development"
    //     ? "WelcomeToRyvo_2022"
    //     : ""
    // );
    disableScroll.on();
  }, []);

  const authValidation = () => {
    if (token === "9f8da8999c2d11bc8a456fe1264a054c68bf3c2133b79dd01a45d625369b4dc0") {
      localStorage.setItem("authToken", token);
      navigate("/posts");
    }
  };

  return (
    <>
      <MobileContainer style={{ height: "100vh" }}>
        <center>
          <AuthWrapper>
            <input
              value={token}
              onInput={e => setToken(e.target.value)}
              style={{
                fontSize: "18px",
                marginBottom: 10,
                width: "90%",
                padding: "15px",
              }}
              placeholder="Auth"
            />
            <Button type="button" onClick={authValidation}>Login</Button>
          </AuthWrapper>
        </center>
      </MobileContainer>
    </>
  );
}

const AuthWrapper = styled.div`
  margin: 35vh auto;
  height: 100px;
`;

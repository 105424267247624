import React, { useEffect, useState } from "react";
import { CustomButton, MobileContainer } from "../../components/global";
import styled from "styled-components";
import {
  FaArrowLeft,
  FaUser,
  FaComment,
  FaFlag,
  FaThumbsUp,
  FaCalendar,
} from "react-icons/fa/";
import { Row, Col } from "react-grid-system";
import disableScroll from "disable-scroll";
import { deletePosts, getCurrentPost } from "../../services/posts.services";
import Moment from "react-moment";
import { Toast } from "antd-mobile";
import { deleteReport } from "../../services/reports.service";
import { useNavigate, useParams, useLocation } from "react-router-dom";

export default function PostDetail() {
  const { id } = useParams();
  const location = useLocation();

  const [post, setPost] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    disableScroll.off();
    getCurrentPost(id).then((res) => {
      setPost(res.post);
    });
  }, [id]);


  function archivePost() {
    var reportId = location.state.reportId;
    deleteReport(reportId).then((res) => {
      navigate('../');
      Toast.success("Report deleted", 2);
    });
  }

  function deletePost() {
    deletePosts(id).then((res) => {
      navigate('../');
      Toast.success("Post deleted", 2);
    });
  }


  return (
    <>
      <MobileContainer>
        <BackLink
          onClick={() => navigate('../')}>
          <Row align="center" gutterWidth={20}>
            <FaArrowLeft />
            <span>Back</span>
          </Row>
        </BackLink>

        <PostImage src={post?.media} alt="" />

        <CounterSection>
          <center>
            <Row justify="around" align="center">
              <Col>
                <CounterWrapper>
                  <FaComment />
                  <p>{post?.countComments}</p>
                </CounterWrapper>
              </Col>
              <Col>
                <CounterWrapper>
                  <FaThumbsUp />
                  <p>{post?.countVotes}</p>
                </CounterWrapper>
              </Col>
              <Col>
                <CounterWrapper>
                  <FaFlag />
                  <p>{post?.countReports}</p>
                </CounterWrapper>
              </Col>
            </Row>
          </center>
        </CounterSection>

        <InfoSection>
          <h2>{post?.description ?? "No description"}</h2>
          <Row justify="between" align="center" style={{ padding: "0 20px" }}>
            <Row align="center" style={{ padding: "0 20px" }}>
              <FaUser style={{ fontSize: "16px", marginRight: "10px" }} />
              <h3>{post?.user.username}</h3>
            </Row>
            <Row align="center" style={{ padding: "0 20px" }}>
              <FaCalendar style={{ fontSize: "16px", marginRight: "10px" }} />
              <h3>
                <Moment format="DD/MM/YYYY">{post?.date}</Moment>
              </h3>
            </Row>
          </Row>
        </InfoSection>
        <ActionSection>
          <Row gutterWidth={8}>
            <Col xs={6}>
              <CustomButton type="button" onClick={deletePost}>
                Delete
              </CustomButton>
            </Col>
            <Col xs={6}>
              <CustomButton
                style={{ background: "#49C628" }}
                type="button"
                onClick={archivePost}
              >
                Archive
              </CustomButton>
            </Col>
          </Row>
        </ActionSection>
      </MobileContainer>
    </>
  );

}

const ActionSection = styled.div`
  margin-top: 20px;
`;

const InfoSection = styled.div`
  h4 {
    font-weight: 400;
    margin: 0;
  }

  h3 {
    font-size: 13px;
  }

  h2 {
    font-weight: 300;
    font-size: 20px;
    border-bottom: 0.1px solid #2f4f4f;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }

  padding-bottom: 10px;
  border-bottom: 0.1px solid #2f4f4f;
`;

const CounterSection = styled.div`
  margin: 5px 0;
  padding: 10px 0 20px 0;
  // border-top: 0.1px solid #2f4f4f;
  border-bottom: 0.1px solid #2f4f4f;
`;

const CounterWrapper = styled.div`
  p {
    margin: 0;
    margin-top: 5px;
    font-weight: bold;
  }
`;

const PostImage = styled.img`
  width: 100%;
  border-radius: 15px;
  height: 400px;
  object-fit: cover;
  background: grey;
`;

const BackLink = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: semibold;
  color: #00b2ff;
  margin-top: 10x;
  margin-bottom: 40px;

  span {
    margin-left: 5px;
    font-size: 16px;
  }
`;

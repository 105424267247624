import React from "react";
import styled from "styled-components";
import { Row } from "react-grid-system";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import "../styles/customContest.css";
import { useNavigate } from "react-router-dom";

export default function CustomContest(props) {
  const navigate = useNavigate();

  const { report } = props;

  return (
    <>
      <Swipeout
        style={{
          backgroundColor: "#FF007C !important",
          margin: "15px 0px",
        }}
        right={[
          {
            text: "Delete",
            onPress: props.onDelete,
            style: {
              backgroundColor: "#F82A19",
              color: "white",
              borderRadius: "0 15px 15px 0",
            },
          },
        ]}
        left={[
          {
            text: "Archive",
            onPress: props.onArchive,
            style: {
              backgroundColor: "#4CD964",
              color: "white",
              borderRadius: "15px 0 0 15px",
            },
          },
        ]}
      >
        <PostCard
          onClick={() => navigate(`/contest/${report.contestReported._id}`, { state: { reportId: report._id } })}
          style={{
            backgroundImage: `linear-gradient(to top, rgba(255, 0, 77, 1), rgba(255, 0, 124, 0.8)),url(${report.contestReported.media})`,
          }}
        >
          <CardContent>
            <h3>{report.contestReported.title}</h3>
            <h5>{report.contestReported.subtitle}</h5>
            <p>{report.contestReported.description}</p>
            <br />
            <br />
            <p>
              User : <b>{report.user.username}</b>
              <br />
              <br />
              Report Message :{" "}
              <b>
                {report.message.length > 0 ? report.message : "Aucun message"}
              </b>
            </p>
            <Row style={{ margin: "20px 0px" }}>
              {report.tags.map((tag) =>
                report.tags.length > 1 ? (
                  <ReportTag key={tag}>{tag}</ReportTag>
                ) : (
                  <ReportTag key={tag}>{tag}</ReportTag>
                )
              )}
            </Row>
          </CardContent>
        </PostCard>
      </Swipeout>
    </>
  );
}

const ReportTag = styled.div`
  padding: 3px 10px;
  background: white;
  color: #212121;
  font-size: 10px;
  font-weight: bold;
  width: fit-content;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const CardContent = styled.div`
  color: white;
  padding: 10px;

  h3 {
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    text-transform: capitalize;
  }

  h5 {
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
    font-size: 18px;
    text-transform: capitalize;
  }

  p {
    font-weight: 300;
    margin: 0;
    margin-top: 30px;
    font-size: 14px;
  }
`;

const PostCard = styled.div`
  height: 100%;
  background: #ff007c;
  padding: 10px;
  border-radius: 15px;
  background-size: cover;
  margin: 0;
`;

import React, { Component } from "react";
import { MobileContainer } from "../../components/global";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import disableScroll from "disable-scroll";
import { deleteContests, getContests, shuffleContests } from "../../services/contests.service";
import { Toast } from "antd-mobile";
import CustomContest from "../../components/CustomContest";
import { deleteReport } from "../../services/reports.service";
import { Row } from "react-grid-system";
import { CustomButton } from "../../components/global";

class ContestsPage extends Component {
  state = {
    isFocused: false,
    index: 0,
  };

  componentDidMount() {
    disableScroll.off();
    const { index } = this.state;

    getContests(index).then((res) => {
      console.log(res);
      this.setState({ contestsObj: res });
    });
  }

  archiveContest(id) {
    deleteReport(id).then((res) => {
      Toast.success("Report deleted", 2, () => window.location.reload());
    });
  }

  deleteContest(id) {
    deleteContests(id).then((res) => {
      Toast.success("Post deleted", 2, () => window.location.reload());
    });
  }

  shuffle() {
    shuffleContests().then((res) => {
      Toast.success("Contests Shuffled", 2);
    });
  }
  render() {
    const { contestsObj } = this.state;

    return (
      <>
        <CustomNavbar title="contests" />
        <MobileContainer style={{ padding: "20px", paddingTop: "0px" }}>
          <Row align="center" justify="start">
            <CustomButton type='button' onClick={() => this.shuffle()}>Shuffle Contests</CustomButton>
          </Row>
          <br />
          {contestsObj?.contests?.map((report) => {
            return (
              <div key={report._id}>
                <CustomContest
                  report={report}
                  onDelete={() =>
                    this.deleteContest(report.contestReported._id)
                  }
                  onArchive={() => this.archiveContest(report._id)}
                />
              </div>
            );
          })}
        </MobileContainer>

        <CustomTabBar />
      </>
    );
  }
}

export default ContestsPage;



export const catchError = (error: any) => {
  if (error?.response?.status === 401) {
    // localStorage.clear();
    // window.location.reload();
    return;
  }
  const errorMessage =
    error?.response?.data?.errorMessage ??
    error?.errorMessage ??
    "une erreur s'est produite";

  return {
    status: error?.response?.status ?? 500,
    message: `${errorMessage}`,
    error: error?.response?.data?.error ?? {},
  };
};

import React, { Component } from "react";
import { MobileContainer } from "../../components/global";
import CustomTabBar from "../../components/CustomTabBar";
import CustomNavbar from "../../components/CustomNavbar";
import CustomPost from "../../components/CustomPost";
import disableScroll from "disable-scroll";
import { deletePosts, getPosts } from "../../services/posts.services";
import { deleteReport } from "../../services/reports.service";
import { Toast } from "antd-mobile";

class PostsPage extends Component {
  state = {
    index: 0,
  };

  componentDidMount() {
    const { index } = this.state;
    disableScroll.off();

    getPosts(index).then((res) => {
      console.log("RES POSTS", res.posts);
      this.setState({
        postsObj: res,
        pages: Math.ceil(res.count / 50),
      });
    });
  }

  archivePost(id, index) {
    deleteReport(id).then((res) => {
      var tmpArray = [...this.state.postsObj.posts];
      tmpArray.splice(index, 1);
      var tmpObj = this.state.postsObj;
      tmpObj.posts = tmpArray;
      tmpObj.count--;
      this.setState({ postsObj: tmpObj });
      Toast.success("Report deleted", 2);
    });
  }

  deletePost(id, index) {
    deletePosts(id).then((res) => {
      var tmpArray = [...this.state.postsObj.posts];
      tmpArray.splice(index, 1);
      var tmpObj = this.state.postsObj;
      tmpObj.posts = tmpArray;
      tmpObj.count--;
      this.setState({ postsObj: tmpObj });
      Toast.success("Post deleted", 2);
    });
  }

  render() {
    const { postsObj } = this.state;
    return (
      <>
        <CustomNavbar title="posts" />
        <MobileContainer style={{ padding: "5px 20px" }}>
          {postsObj && postsObj.posts && postsObj.posts.map((post, index) => (
            <CustomPost
              key={post?._id}
              onArchive={() => this.archivePost(post?._id, index)}
              onDelete={() => this.deletePost(post?.postReported?._id, index)}
              subtype={post?.subtype}
              message={post?.message}
              id={post?.postReported?._id}
              image={post?.postReported?.mediaMin}
              tags={post?.tags}
              reportId={post?._id}
            />
          ))}
        </MobileContainer>
        <CustomTabBar />
      </>
    );
  }
}

export default PostsPage;

import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import {
  FaClock,
  FaComment,
  FaCrown,
  FaEdit,
  FaStickyNote,
  FaUser,
  // FaInfo,
} from "react-icons/fa/";

class CustomTabBar extends Component {
  state = {
    scrolled: false,
    scrollPostion: 0,
  };

  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  };

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100);

    this.setState({
      scrollPostion,
    });
  };

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      if (!this.state.scrolled) {
        this.setState({ scrolled: true });
      }
    } else {
      if (this.state.scrolled) {
        this.setState({ scrolled: false });
      }
    }
  };

  componentDidMount() {
    if (localStorage.getItem("authToken") !== "9f8da8999c2d11bc8a456fe1264a054c68bf3c2133b79dd01a45d625369b4dc0") {
      window.location.href = "/auth"
    }
    window.addEventListener("scroll", this.handleScroll);
    this.listenToScrollEvent();
  }

  getCurrentLocation(page) {
    var currentLocation = window.location.pathname;
    if (page === currentLocation) {
      return "white";
    } else {
      return "grey";
    }
  }

  render() {
    return (
      <>
        <BarWrapper
          style={{ paddingBottom: this.state.scrolled ? "40px" : "40px" }}
        >
          <Row align="center" justify="around">
            <Col style={{ padding: "0" }}>
              <TabButton to="/posts">
                <center>
                  <FaStickyNote
                    size="18px"
                    color={this.getCurrentLocation("/posts")}
                  />
                  <br />
                  <span style={{ color: this.getCurrentLocation("/posts") }}>
                    posts
                  </span>
                </center>
              </TabButton>
            </Col>
            <Col style={{ padding: "0" }}>
              <TabButton to="/contests">
                <center>
                  <FaCrown
                    size="18px"
                    color={this.getCurrentLocation("/contests")}
                  />
                  <br />
                  <span style={{ color: this.getCurrentLocation("/contests") }}>
                    contests
                  </span>
                </center>
              </TabButton>
            </Col>
            <Col style={{ padding: "0" }}>
              <TabButton to="/contests/pending">
                <center>
                  <FaClock
                    size="18px"
                    color={this.getCurrentLocation("/contests/pending")}
                  />
                  <br />
                  <span
                    style={{
                      color: this.getCurrentLocation("/contests/pending"),
                    }}
                  >
                    pending
                  </span>
                </center>
              </TabButton>
            </Col>
            <Col style={{ padding: "0" }}>
              <TabButton to="/comments">
                <center>
                  <FaComment
                    size="18px"
                    color={this.getCurrentLocation("/comments")}
                  />
                  <br />
                  <span style={{ color: this.getCurrentLocation("/comments") }}>
                    comments
                  </span>
                </center>
              </TabButton>
            </Col>
            <Col style={{ padding: "0" }}>
              <TabButton to="/users-reports">
                <center>
                  <FaUser
                    size="18px"
                    color={this.getCurrentLocation("/users-reports")}
                  />
                  <br />
                  <span style={{ color: this.getCurrentLocation("/users-reports") }}>
                    users reports
                  </span>
                </center>
              </TabButton>
            </Col>
            <Col style={{ padding: "0" }}>
              <TabButton to="/users">
                <center>
                  <FaUser
                    size="18px"
                    color={this.getCurrentLocation("/users")}
                  />
                  <br />
                  <span style={{ color: this.getCurrentLocation("/users") }}>
                    users
                  </span>
                </center>
              </TabButton>
            </Col>
            <Col style={{ padding: "0" }}>
              <TabButton to="/policies">
                <center>
                  <FaEdit
                    size="18px"
                    color={this.getCurrentLocation("/policies")}
                  />
                  <br />
                  <span style={{ color: this.getCurrentLocation("/policies") }}>
                    policies
                  </span>
                </center>
              </TabButton>
            </Col>
          </Row>
        </BarWrapper>
      </>
    );
  }
}

export default CustomTabBar;

const BarWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #303030;
  margin: 0 auto;
  padding: 15px 20px;
  z-index: 99;
  max-width: 410px;
`;

const TabButton = styled(Link)`
  cursor: pointer;
  text-decoration: none;

  span {
    color: white;
    text-transform: capitalize;
    font-size: 10px;
    text-align: center !important;
    font-weight: 600;
  }
`;

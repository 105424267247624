import axios from 'axios';
import { API_URL } from '../config';
import { catchError } from './response';


const getApiRequestService = () => {
  return axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDMyOTJjMjVkYmM2Zjc3Nzc2NzE3ODMiLCJpYXQiOjE2MTQ1NDExOTgsImV4cCI6MTYxNDU0Mjk5OH0._87VhhzHuZTAqe4odrUwQkB4dZ40iLjto0UY6f170VU",
      },
  });
};

export const getReport = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/reports/${id}`);

    return res.data;
  } catch (error) {
    console.log('GET_REPORT', error);

    return catchError(error);
  }
};

export const deleteReport = async (id: any) => {
  try {
    const res = await getApiRequestService().delete(`/reports/${id}`);

    return res.data;
  } catch (error) {
    console.log('DELETE_REPORT', error);

    return catchError(error);
  }
};

// export const getArticle = async (articleId: string) => {
//   try {
//     const res = await getApiRequestService().get(`/article/${articleId}`);
//     return res.data;

//   } catch (error) {
//     console.log('GETARTICLE', error);
//     return catchError(error);
//   }
// }

// export const patchUser = async (data: any) => {
//   try {
//     const body: any = {}
//     for (const [key, value] of Object.entries(data)) {
//       body[key] = value
//     }
    
//     const res = await getApiRequestService().patch("/user/me", body)
//     // console.log('list', res.data);

//     return res.data;
//   } catch (error) {
//     console.log('GETARTICLESLIST', error);

//     return catchError(error);
//   }
// };

// export const sharedArticle = async (slug: any) => {
//   try {
//     const res = await getApiRequestService().post(`/article/${slug}/share`)
//     console.log('shared_article', res.data);

//     return res.data;
//   } catch (error) {
//     console.log('SHARED_ARTICLES', error);

//     return catchError(error);
//   }
// };

import React, { useEffect } from "react";
import { MobileContainer } from "../../components/global";
import disableScroll from "disable-scroll";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  useEffect(() => {
    disableScroll.on();
  }, []);
  const navigate = useNavigate();

  return (
    <MobileContainer style={{ height: "100vh" }}>
      <center>
        <h1 style={{ marginTop: "30vh" }}>404 - No page found</h1>
        <p
          onClick={() => navigate('../')}
          style={{ cursor: "pointer", fontWeight: "700" }}
        >
          Go Back
        </p>
      </center>
    </MobileContainer>
  );

}

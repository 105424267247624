import axios from 'axios';
import { API_URL } from '../config';
import { catchError } from './response';


const getApiRequestService = () => {
  return axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDMyOTJjMjVkYmM2Zjc3Nzc2NzE3ODMiLCJpYXQiOjE2MTQ1NDExOTgsImV4cCI6MTYxNDU0Mjk5OH0._87VhhzHuZTAqe4odrUwQkB4dZ40iLjto0UY6f170VU",
    },
  });
};

export const getContests = async (index: any) => {
  try {
    const res = await getApiRequestService().get(`/contests/reported?page=${index}`);

    return res.data;
  } catch (error) {
    console.log('GET_CONTESTS', error);

    return catchError(error);
  }
};

export const getNewContests = async () => {
  try {
    const res = await getApiRequestService().get(`/contests/news`);

    return res.data;
  } catch (error) {
    console.log('GET_NEW_CONTESTS', error);

    return catchError(error);
  }
};


export const getCurrentContest = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/contests/${id}`);

    return res.data;
  } catch (error) {
    console.log('GET_CURRENT_CONTEST', error);

    return catchError(error);
  }
};

export const deleteContests = async (id: any) => {
  try {
    const res = await getApiRequestService().delete(`/contests/${id}`);

    return res.data;
  } catch (error) {
    console.log('DELETE_CONTESTS', error);

    return catchError(error);
  }
};


export const verifyContest = async (id: any) => {
  try {
    const res = await getApiRequestService().post(`/contests/${id}`)
    console.log('verify_contest', res.data);

    return res.data;
  } catch (error) {
    console.log('CONTEST_VERIFIED', error);

    return catchError(error);
  }
};


export const shuffleContests = async () => {
  try {
    const res = await getApiRequestService().post('/contests/shuffle');

    return res.data;
  } catch (error) {
    console.log('SHUFFLE_CONTESTS', error);

    return catchError(error);
  }
};

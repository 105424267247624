import React, { useState, useEffect } from "react";
import { CustomButton, MobileContainer } from "../../components/global";
import styled from "styled-components";
import { FaArrowLeft, FaBullhorn, FaUser } from "react-icons/fa/";
import { Row, Col } from "react-grid-system";
import disableScroll from "disable-scroll";
import {
  deleteContests,
  getCurrentContest,
  verifyContest,
} from "../../services/contests.service";
import Moment from "react-moment";
import { deleteReport } from "../../services/reports.service";
import { Toast } from "antd-mobile";
import { useNavigate, useParams, useMatch, useLocation } from "react-router-dom";

export default function ContestDetail() {
  const { id } = useParams();
  const [contest, setContest] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    disableScroll.off();
    getCurrentContest(id).then((res) => {
      setContest(res.contest);
    });
  }, [id]);



  function archiveContest() {
    var reportId = location.state.reportId;
    deleteReport(reportId).then((res) => {
      navigate('../');
      Toast.success("Report deleted", 2);
    });
  }

  function acceptContest() {
    var reportId = location.state.reportId;
    verifyContest(id).then((res) => {
      navigate('../');
      Toast.success("Contest verified", 2);
    });
  }

  function deleteContest() {
    deleteContests(id).then((res) => {
      navigate('../');
      Toast.success("Post deleted", 2);
    });
  }
  const matchContest = useMatch("/contest/:id");
  const matchVerify = useMatch("/contest/verify/:id");


  return (
    <MobileContainer>
      <BackLink
        onClick={() => navigate('../')}>
        <Row align="center" gutterWidth={20}>
          <FaArrowLeft />
          <span>Back</span>
        </Row>
      </BackLink>
      <ContestImage src={contest?.media} alt="" />
      <h2>{contest?.title}</h2>
      <p>{contest?.description}</p>
      <small>
        <p>Max Participations:&nbsp;{contest?.maxParticipations}</p>
      </small>
      <small>
        Posted&nbsp;
        <Moment format="DD/MM/YYYY">{contest?.date}</Moment>
      </small>
      <Divider style={{ marginTop: "20px" }} />
      <InfoWrapper>
        <Row justify="between" style={{ padding: "0 20px" }}>
          <Row align="center">
            <FaUser style={{ fontSize: "12px" }} />
            <h5>{contest?.user?.username}</h5>
          </Row>
          <Row align="center">
            <FaBullhorn style={{ fontSize: "12px" }} />
            <h5>{contest?.category?.name}</h5>
          </Row>
        </Row>
      </InfoWrapper>
      <Divider />

      <ActionSection>
        <Row gutterWidth={8}>
          <Col xs={6}>
            <CustomButton type="button" onClick={deleteContest}>
              Delete
            </CustomButton>
          </Col>
          <Col xs={6}>
            {matchContest && (
              <CustomButton
                style={{ background: "#49C628" }}
                type="button"
                onClick={archiveContest}
              >
                Archive
              </CustomButton>
            )}
            {matchVerify && (
              <CustomButton
                style={{ background: "#49C628" }}
                type="button"
                onClick={acceptContest}
              >
                Verify
              </CustomButton>
            )}
          </Col>
        </Row>
      </ActionSection>
    </MobileContainer>

  );
}


const ActionSection = styled.div`
  margin-top: 20px;
`;

const InfoWrapper = styled.div`
  padding: 0 20px;
  margin: 0;

  h5 {
    margin-left: 10px;
    text-transform: capitalize;
  }
`;

const Divider = styled.div`
  margin: 0px 0;
  border-bottom: 0.1px solid #2f4f4f;
`;

const ContestImage = styled.img`
  width: 100%;
  border-radius: 15px;
  height: 300px;
  object-fit: cover;
  background: grey;
`;

const BackLink = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: semibold;
  color: #00b2ff;
  margin-top: 10x;
  margin-bottom: 40px;

  span {
    margin-left: 5px;
    font-size: 16px;
  }
`;

import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import avatar from "../assets/images/post-wp.jpg";

class CustomReport extends Component {
  render() {
    const { user } = this.props;

    return (
      <>
        <Swipeout
          style={{ margin: "10px 0" }}
          left={[
            {
              text: "Accept",
              onPress: this.props.onAccept,
              style: {
                backgroundColor: "#49C628",
                color: "white",
                borderRadius: "10px 0 0 10px",
              },
              className: "custom-class-1",
            },
          ]}
          right={[
            {
              text: "Delete",
              onPress: this.props.onDelete,
              style: {
                backgroundColor: "#F82A19",
                color: "white",
                borderRadius: "0 10px 10px 0",
              },
              className: "custom-class-2",
            },
          ]}
        >
          <UsersWrapper>
            <Row align="center" justify="start">
              <Col xs={3 / 2}>
                <UserAvatar src={avatar} alt="" />
              </Col>
              <Col xs={10}>
                <UsersInfos>
                  <Row align="center" gutterWidth={0}>
                    <h5>{user.username}</h5>
                    <small>{user.date}</small>
                  </Row>
                </UsersInfos>
              </Col>

              <Col xs={12} style={{ marginTop: "10px" }}>
                <UsersInfos>
                  <p>
                    Number of reports : <b>{user.reports}</b>
                  </p>
                </UsersInfos>
              </Col>
            </Row>
          </UsersWrapper>
        </Swipeout>
      </>
    );
  }
}

export default CustomReport;

const UserAvatar = styled.img`
  height: 25px;
  width: 25px;
  border-radius: 60px;
  object-fit: cover;
`;

const UsersInfos = styled.div`
  color: white;

  h5 {
    margin: 0;
    font-size: 13px;
    margin-right: 10px;
  }
  small {
    margin: 0;
    font-size: 10px;
  }
  p {
    margin: 0;
  }
`;

const UsersWrapper = styled.div`
  background: #212121;
  padding: 15px 20px;
  border-radius: 5px;
`;
